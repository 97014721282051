module.exports = {
    "add.svg": require('../assets/add.svg'),
"addfiles.svg": require('../assets/addfiles.svg'),
"android-chrome-192x192.png": require('../assets/android-chrome-192x192.png'),
"android-chrome-512x512.png": require('../assets/android-chrome-512x512.png'),
"apple-touch-icon.png": require('../assets/apple-touch-icon.png'),
"bg.svg": require('../assets/bg.svg'),
"blue_file.svg": require('../assets/blue_file.svg'),
"close-16.svg": require('../assets/close-16.svg'),
"completed.svg": require('../assets/completed.svg'),
"copy-16.svg": require('../assets/copy-16.svg'),
"dl.svg": require('../assets/dl.svg'),
"error.svg": require('../assets/error.svg'),
"favicon-16x16.png": require('../assets/favicon-16x16.png'),
"favicon-32x32.png": require('../assets/favicon-32x32.png'),
"feedback.svg": require('../assets/feedback.svg'),
"firefox_logo-only.svg": require('../assets/firefox_logo-only.svg'),
"icon.svg": require('../assets/icon.svg'),
"intro.svg": require('../assets/intro.svg'),
"lock.svg": require('../assets/lock.svg'),
"master-logo.svg": require('../assets/master-logo.svg'),
"mozilla-logo.svg": require('../assets/mozilla-logo.svg'),
"new_logo.png": require('../assets/new_logo.png'),
"notFound.svg": require('../assets/notFound.svg'),
"safari-pinned-tab.svg": require('../assets/safari-pinned-tab.svg'),
"select-arrow.svg": require('../assets/select-arrow.svg'),
"send-fb.jpg": require('../assets/send-fb.jpg'),
"send-twitter.jpg": require('../assets/send-twitter.jpg'),
"share-24.svg": require('../assets/share-24.svg'),
"user.svg": require('../assets/user.svg'),
"wordmark.svg": require('../assets/wordmark.svg')
  };